import * as React from 'react';

import Master from '../components/Master';
import Products from '../components/Products';

const Page = ({ pageContext }) => (
    <Master title={pageContext.title} description={pageContext.description}>
        <div className="px-5">
            <Products data={pageContext} />
        </div>
    </Master>
);

export default Page;
